import { css, cx } from '@emotion/css';
import React, { FC } from 'react';

import { colorManipulator } from '@grafana/data';
import { useTheme2 } from '@grafana/ui';

export interface BrandComponentProps {
  className?: string;
  children?: JSX.Element | JSX.Element[];
}

// Define the custom CSS for the logo size with !important to override any other styles
const logoStyle = css({
  width: '400px !important',  // Enforce the width with !important
  height: 'auto !important',  // Let the height auto adjust based on aspect ratio
  display: 'block',           // Ensure the logo is displayed as a block element
  margin: '0 auto',           // Center the logo horizontally
  maxWidth: '100% !important', // Ensure the logo is not constrained by any maximum width
});

// Define the custom CSS for the menu logo size with !important to ensure it applies
const menuLogoStyle = css({
  width: '200px !important',  // Adjusted size for the menu logo
  height: 'auto !important',  // Let the height auto adjust for aspect ratio
  display: 'block',           // Ensure the logo is a block element
  margin: '0 auto',           // Center the menu logo
  maxWidth: '100% !important', // Prevent any width constraints from parent elements
});

// Modify the login logo
export const LoginLogo: FC<BrandComponentProps & { logo?: string }> = ({ className, logo }) => {
  return (
    <img
      className={cx(className, logoStyle)}  // Combine passed className with custom size
      src={`${logo ? logo : 'public/img/grafana_icon.svg'}`}  // Use custom logo if provided
      alt="Grafana"
    />
  );
};

// Modify the background for login
const LoginBackground: FC<BrandComponentProps> = ({ className, children }) => {
  const theme = useTheme2();

  const background = css({
    '&:before': {
      content: '""',
      position: 'fixed',
      left: 0,
      right: 0,
      bottom: 0,
      top: 0,
      background: `url(public/img/g8_login_${theme.isDark ? 'dark' : 'light'}.svg)`,
      backgroundPosition: 'top center',
      backgroundSize: 'auto',
      backgroundRepeat: 'no-repeat',

      opacity: 0,
      transition: 'opacity 3s ease-in-out',

      [theme.breakpoints.up('md')]: {
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      },
    },
  });

  return <div className={cx(background, className)}>{children}</div>;
};

// Modify the menu logo
const MenuLogo: FC<BrandComponentProps> = ({ className }) => {
  return (
    <img
      className={cx(className, menuLogoStyle)}  // Use the custom menu logo size
      src="public/img/grafana_icon.svg"  // Menu logo
      alt="Grafana"
    />
  );
};

const LoginBoxBackground = () => {
  const theme = useTheme2();
  return css({
    background: colorManipulator.alpha(theme.colors.background.primary, 0.7),
    backgroundSize: 'cover',
  });
};

export class Branding {
  static LoginLogo = LoginLogo;
  static LoginBackground = LoginBackground;
  static MenuLogo = MenuLogo;
  static LoginBoxBackground = LoginBoxBackground;
  static AppTitle = '';
  static LoginTitle = '';
  static HideEdition = false;
  static GetLoginSubTitle = (): null | string => {
    return null;
  };
}
